import React from "react"
import { Link, graphql } from "gatsby"
import Image from "../components/Image"
import Text from "../components/Text"
import { Section, Columns } from "../components/Containers"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"
import CloseIcon from "../components/CloseIcon"
import GetStarted from "../components/CTA/GetStarted"

import { Dialog } from "@blueprintjs/core"

class AnesthesiaOptionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
      componentShownInDialog: {}
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  componentDidUpdate(prevState) {
    if (
      this.state.dialogIsOpen !== prevState.dialogIsOpen &&
      this.state.dialogIsOpen
    ) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }
  }

  toggleDialog(component) {
    this.setState(prevState => ({
      dialogIsOpen: !prevState.dialogIsOpen,
      componentShownInDialog: component
    }))
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]

    return (
      <SharedStateProvider>
        <Layout pageTitle="an-options" pageHeading={post.heading}>
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
          />

          <Dialog
            canOutsideClickClose={true}
            enforceFocus={true}
            autoFocus={true}
            canEscapeKeyClose={true}
            isOpen={this.state.dialogIsOpen}
            usePortal={true}
            onClose={this.toggleDialog}
            className="anesthesia-dialog">
            {this.state.componentShownInDialog}
          </Dialog>

          <Section>
            <Columns sideColumnsSize={5}>
              <Text
                className="anesthesia-top-blurb joshua-tree-content"
                text={post.topBlurbs}
              />
            </Columns>
          </Section>

          <div className="body-sections anesthesia-body-section color-back">
            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <Text text={post.body} />
              </div>
              <div className="column is-5"></div>
            </div>
            <div className="columns has-text-centered color-back anesthesia-modal">
              <div className="column is-2"></div>
              <div className="column">
                <div className="button-group-wrapper">
                  <div className="button-group is-centered has-4-button anesthesia-modal-buttons">
                    {post.anesthesiaTypes.buttons.map(modal => (
                      <React.Fragment key={modal.heading}>
                        {modal.href ? (
                          <Link to={modal.href}>
                            <a className="standard-button contained">
                              <Text as="span" text={modal.buttonText} />
                            </a>
                          </Link>
                        ) : (
                          <a
                            className="standard-button contained"
                            onClick={() =>
                              this.toggleDialog(
                                <AnesthesiaModal
                                  modalHeading={modal.heading}
                                  modalBlurb={modal.blurb}
                                  youtube={modal.youtube}
                                  toggleDialog={this.toggleDialog}
                                />
                              )
                            }>
                            {modal.buttonText}
                          </a>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="column is-2"></div>
            </div>
          </div>

          <GetStarted
            className="get-started-cta has-text-centered anesthesia-options-cta"
            centerHeading
            headingSideColumnIs={5}
            sideColumnIs={4}
            subheading={post.getStarted.subheading}
            heading={post.getStarted.heading}
            paragraph={post.getStarted.blurb}
            buttonText={post.getStarted.buttonText}
            buttonUrl={post.getStarted.href}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

function AnesthesiaModal(props) {
  return (
    <div className="has-text-centered dialog-content">
      <CloseIcon onClick={props.toggleDialog} />
      <Text as="h3" text={props.modalHeading} />
      <Text as="p" className="large" text={props.modalBlurb} />
      {props.youtube && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
          controls={true}
          playing={true}
          onlyButton={true}
          buttonClass="contained"
        />
      )}
      {!props.youtube && <div style={{ height: "80px" }}></div>}
    </div>
  )
}

export const pageQuery = graphql`
  query anesthesiaOptionsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        anesthesiaTypes {
          buttons {
            buttonText
            heading
            blurb
            youtube
            href
          }
          blurb
          noInlinkBlurb
          heading
        }
        youtube
        imageId
        body
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
        heading
        title
        topBlurbs
      }
    }
  }
`

export default AnesthesiaOptionsPage
